<template>
  <aside class="sidebar" :class="{ open: isOpen }">
    <div class="sidebar-content">
      <h2>Categories</h2>
      <ul>
        <li v-for="category in categories" :key="category">
          <router-link :to="`/category/${category}`">{{ category }}</router-link>
        </li>
      </ul>
      <h2>Tags</h2>
      <ul>
        <li v-for="tag in limitedTags" :key="tag">
          <router-link :to="`/tag/${tag}`">{{ tag }}</router-link>
        </li>
      </ul>
      <h2>Legal</h2>
      <ul>
        <li>
          <a href="/terms.html" target="_blank">Terms of Service</a>
        </li>
        <li>
          <a href="/privacy.html" target="_blank">Privacy Policy</a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "AppSidebar",
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    limitedTags() {
      const excludedTags = ['Causal', '.io', 'Driving', 'Sports', 'Action', 'Shooting', 'Puzzle', 'Clicker', 'Adventure', 'Beauty'];
      return this.tags.filter(tag => !excludedTags.includes(tag)).slice(0, 20); // 保留不超过 20 个标签
    }
  }
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  background-color: #2c3e50;
  color: #ecf0f1;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.sidebar.open {
  width: 220px;
}

.sidebar-content {
  overflow-y: auto; /* 允许垂直滚动 */
  height: calc(100% - 60px); /* 减去 header 的高度 */
  padding-right: 10px;
}

.sidebar h2 {
  font-size: 18px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #34495e;
  color: #ecf0f1;
  text-align: center;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #ecf0f1;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar a:hover {
  background-color: #1abc9c;
  color: #ffffff;
}
</style> 