import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CategoryView from "../views/CategoryView.vue";
import GameDetail from "../views/GameDetail.vue";
import TagView from "../views/TagView.vue";
const routes = [
  { path: "/", component: Home },
  { path: "/category/:name", component: CategoryView },
  { path: "/game/:name", component: GameDetail },
  { path: "/tag/:name", component: TagView }
];
const router = createRouter({ history: createWebHistory(), routes });
export default router;
