<template>
  <div class="app-container">
    <AppHeader @toggle-sidebar="toggleSidebar" />
    <div class="content-container">
      <AppSidebar :categories="filteredCategories" :tags="filteredTags" :isOpen="sidebarOpen" />
      <main class="main-content">
        <div v-if="playedGames.length" class="category-section">
          <h2>History</h2>
          <div class="games-list">
            <GameItem v-for="game in playedGames" :key="game.name" :game="game" />
          </div>
        </div>
        <div v-for="category in filteredCategories" :key="category" class="category-section">
          <div class="category-header">
            <router-link :to="`/category/${category}`" class="category-link">
              <h2>{{ category }}</h2>
            </router-link>
            <button @click="goToCategory(category)" class="more-button">More</button>
          </div>
          <div class="games-list">
            <GameItem v-for="game in getGamesByCategory(category)" :key="game.name" :game="game" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import GameItem from '../components/GameItem.vue';
import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';

export default {
  name: "HomeView",
  components: { GameItem, AppHeader, AppSidebar },
  data() {
    return {
      categories: [],
      tags: [],
      games: [],
      playedGames: [],
      sidebarOpen: false
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(category => category);
    },
    filteredTags() {
      const excludedTags = ['Causal', '.io', 'Driving', 'Sports', 'Action', 'Shooting', 'Puzzle', 'Clicker', 'Adventure', 'Beauty'];
      const allTags = new Set(this.games.flatMap(game => game.tags ? game.tags.split(',').map(tag => tag.trim()) : []));
      const uniqueTags = [...allTags].filter(tag => !this.categories.includes(tag) && !excludedTags.includes(tag));
      return uniqueTags.slice(0, 20); // 保留不超过 20 个标签
    }
  },
  methods: {
    getGamesByCategory(category) {
      return this.games.filter(game => game.class === category).slice(0, 30);
    },
    hasMoreGames(category) {
      return this.games.filter(game => game.class === category).length > 30;
    },
    goToCategory(category) {
      this.$router.push(`/category/${category}`);
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    loadPlayedGames() {
      const played = JSON.parse(localStorage.getItem('playedGames')) || [];
      this.playedGames = this.games.filter(game => played.includes(game.router));
    }
  },
  created() {
    axios.get('/crazygames.json').then(response => {
      const categories = new Set();
      response.data.forEach(game => {
        categories.add(game.class);
        this.games.push(game);
      });
      this.categories = Array.from(categories).filter(category => category);
      this.loadPlayedGames();
    }).catch(error => {
      console.error("Error loading data:", error);
    });
  }
};
</script>

<style scoped>
.app-container {
  /*margin-top: 60px; /* 确保正文部分不被 header 遮挡 */
}

.category-section {
  margin-bottom: 40px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-link {
  text-decoration: none;
  color: inherit;
}

.more-button {
  margin-left: auto;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 12px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
</style>
