<template>
  <div class="game-item" @click="goToGameDetail">
    <div v-if="game.rating > 9.0" class="hot-badge">HOT</div>
    <div v-else-if="isNewRelease" class="new-badge">NEW</div>
    <img :src="game.cover || defaultImage" alt="game.name" class="game-image" />
    <div class="game-name">{{ game.name }}</div>
  </div>
</template>

<script>
export default {
  name: "GameItem",
  props: {
    game: Object
  },
  data() {
    return {
      defaultImage: '/path/to/default/image.png' // 替换为实际的默认图片路径
    };
  },
  computed: {
    isNewRelease() {
      const releaseDate = new Date(this.game.released);
      return releaseDate.getMonth() > 8; // 9 月份之后
    }
  },
  methods: {
    goToGameDetail() {
      if (this.game.router) {
        this.$router.push(`/game/${this.game.router}`); // 确保游戏详情页的路由是 `/game/:router`
      } else {
        console.error("Game router is missing");
      }
    }
  }
};
</script>

<style scoped>
.game-item {
  position: relative; /* 为了定位角标和悬停名称 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px; /* 减少内边距以控制总间距 */
  box-sizing: border-box;
  cursor: pointer;
  margin: 0; /* 移除外边距以便更好地控制间距 */
}

.hot-badge {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: red;
  color: white;
  padding: 1px 4px; /* 调整内边距 */
  font-size: 10px; /* 调整字体大小 */
  font-weight: bold;
  border-radius: 3px;
}

.new-badge {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: blue;
  color: white;
  padding: 1px 4px; /* 调整内边距 */
  font-size: 10px; /* 调整字体大小 */
  font-weight: bold;
  border-radius: 3px;
}

.game-image {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: cover;
  display: block;
}

.game-name {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px; /* 调整字体大小以确保尽可能一行显示 */
  line-height: 1.2;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap; /* 确保文本不换行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}

.game-item:hover .game-name {
  opacity: 1;
}
</style> 