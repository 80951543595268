<template>
  <header class="app-header">
    <button class="toggle-button" @click="$emit('toggle-sidebar')">☰</button>
    <div class="search-container">
      <div class="gcse-search"></div>
    </div>
    <button class="home-button" @click="goHome">Home</button>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {
    goHome() {
      this.$router.push("/");
    },
    googleSearch() {
      const script = document.createElement("script");
      script.src = "https://cse.google.com/cse.js?cx=22cebcba626cb44ec";
      script.async = true;
      document.head.appendChild(script);
    },
  },
  mounted() {
    this.googleSearch();
  },
};
</script>

<style scoped>
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  z-index: 1100;
  height: 60px;
}

.toggle-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}

.search-container {
  flex: 1;
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 1px auto;
  border-radius: 8px;
  overflow: hidden;
}

.gcse-search {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0;
}

.home-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}

@media (min-width: 1200px) {
  .search-container {
    max-width: 1000px;
  }
}
</style>
