<template>
  <div class="tag-container">
    <AppHeader @toggle-sidebar="toggleSidebar" />
    <div class="content-container">
      <AppSidebar :categories="categories" :tags="filteredTags" :isOpen="sidebarOpen" />
      <main class="main-content">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <span>&gt;</span>
          <span>Tag: {{ $route.params.name }}</span>
        </nav>
        <div class="games-list">
          <GameItem v-for="game in filteredGames" :key="game.name" :game="game" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';
import GameItem from '../components/GameItem.vue';
import { loadGamesData } from '../utils/dataLoader';

export default {
  name: "TagView",
  components: { AppHeader, AppSidebar, GameItem },
  data() {
    return {
      games: [],
      categories: [],
      tags: [],
      sidebarOpen: false
    };
  },
  computed: {
    filteredGames() {
      return this.games.filter(game => game.tags && game.tags.includes(this.$route.params.name));
    },
    filteredTags() {
      const excludedTags = ['Causal', '.io', 'Driving', 'Sports', 'Action', 'Shooting', 'Puzzle', 'Clicker', 'Adventure', 'Beauty'];
      const allTags = new Set(this.games.flatMap(game => game.tags ? game.tags.split(',').map(tag => tag.trim()) : []));
      return [...allTags].filter(tag => !this.categories.includes(tag) && !excludedTags.includes(tag));
    }
  },
  created() {
    loadGamesData().then(data => {
      this.games = data;
      this.categories = [...new Set(this.games.map(game => game.class))];
      this.tags = this.filteredTags;
    }).catch(error => {
      console.error("Error loading data:", error);
    });
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }
};
</script>

<style scoped>
.tag-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
  position: relative;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.breadcrumb a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s;
}

.breadcrumb a:hover {
  color: #0056b3;
}

.breadcrumb span {
  margin: 0 5px;
  color: #333;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
</style> 