<template>
  <div class="category-container">
    <AppHeader @toggle-sidebar="toggleSidebar" />
    <div class="content-container">
      <AppSidebar :categories="categories" :tags="tags" :isOpen="sidebarOpen" />
      <main class="main-content">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <span>&gt;</span>
          <span>{{ $route.params.name }}</span>
        </nav>
        <div class="games-list">
          <div v-for="game in filteredCategoryGames" :key="game.name" class="game-item">
            <router-link :to="`/game/${game.router}`">
              <img :src="game.cover" alt="game.name" class="game-image" />
            </router-link>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { loadGamesData, filterCategories } from '../utils/dataLoader';
import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';

export default {
  name: "CategoryView",
  components: { AppHeader, AppSidebar },
  data() {
    return {
      games: [],
      categories: [],
      tags: [],
      searchQuery: '',
      sidebarOpen: false
    };
  },
  computed: {
    categoryGames() {
      return this.games.filter(game => game.class === this.$route.params.name);
    },
    filteredCategoryGames() {
      return this.categoryGames.filter(game => game.name && game.cover);
    },
    filteredCategories() {
      return filterCategories(this.games);
    }
  },
  methods: {
    searchGames() {
      this.$router.push({ path: `/category/${this.$route.params.name}`, query: { search: this.searchQuery } });
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  },
  created() {
    loadGamesData().then(data => {
      this.games = data;
      this.categories = filterCategories(this.games);
      this.tags = [...new Set(this.games.flatMap(game => game.tags ? game.tags.split(',').map(tag => tag.trim()) : []))];
    }).catch(error => {
      console.error("Error loading data:", error);
    });
  }
};
</script>

<style>
.category-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  position: relative;
}

.main-content {
  margin-top: 60px;
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 14px;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}

.game-item {
  flex: 1 1 calc(20% - 10px);
  box-sizing: border-box;
  margin-bottom: 10px;
  text-align: center;
}

.game-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .game-item {
    flex: 1 1 calc(10% - 10px);
  }
}

@media (max-width: 768px) {
  .game-item {
    flex: 1 1 calc(33.33% - 10px);
  }
}

@media (max-width: 480px) {
  .game-item {
    flex: 1 1 calc(50% - 10px);
  }
}
</style>
