<template>
  <div>
    <AppHeader @toggle-sidebar="toggleSidebar" />
    <div class="content-container">
      <AppSidebar :categories="filteredCategories" :tags="tags" :isOpen="sidebarOpen" />
      <main class="main-content">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <span>&gt;</span>
          <router-link :to="`/category/${game.class}`">{{ game.class }}</router-link>
          <span>&gt;</span>
          <span>{{ game.name }}</span>
        </nav>
        <div class="iframe-wrapper">
          <iframe :src="game.embed" frameborder="0" allow="gamepad *;"></iframe>
        </div>
        <div class="game-details">
          <h1>{{ game.name }}</h1>
          <p class="game-description"><strong>Description:</strong> {{ game.description }}</p>
          <p class="game-rating"><strong>Rating:</strong> {{ game.rating }}</p>
          <p class="game-developer"><strong>Developer:</strong> {{ game.developer }}</p>
          <p class="game-released"><strong>Released:</strong> {{ game.released }}</p>
          <p class="game-updated"><strong>Updated:</strong> {{ game.updated }}</p>
          <div class="tags" v-if="game.tags">
            <strong>Tags:</strong>
            <span v-for="tag in game.tags.split(',')" :key="tag" class="tag" @click="handleTagClick(tag.trim())">{{ tag.trim() }}</span>
          </div>
        </div>
        <div class="recommendations">
          <h2>Recommended Games</h2>
          <div class="games-list">
            <GameItem v-for="recommendedGame in recommendedGames" :key="recommendedGame.name" :game="recommendedGame" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';
import GameItem from '../components/GameItem.vue';

export default {
  components: { AppHeader, AppSidebar, GameItem },
  data() {
    return {
      game: {},
      categories: [],
      tags: [],
      recommendedGames: [],
      searchQuery: '',
      sidebarOpen: false
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(category => category);
    }
  },
  created() {
    this.loadGameData();
  },
  watch: {
    '$route.params.name': 'loadGameData'
  },
  methods: {
    loadGameData() {
      const gameRouter = this.$route.params.name;
      axios.get('/crazygames.json').then(response => {
        const game = response.data.find(g => g.router === gameRouter);
        if (game) {
          this.game = game;
          this.addToHistory(game.router);
          this.loadRecommendedGames(game.class, game.router);
        }
        this.categories = [...new Set(response.data.map(game => game.class))].filter(category => category);
        this.tags = [...new Set(response.data.flatMap(game => game.tags ? game.tags.split(',').map(tag => tag.trim()) : []))];
      }).catch(error => {
        console.error("Error loading data:", error);
      });
    },
    addToHistory(router) {
      let history = JSON.parse(localStorage.getItem('playedGames')) || [];
      if (!history.includes(router)) {
        history.push(router);
        localStorage.setItem('playedGames', JSON.stringify(history));
      }
    },
    loadRecommendedGames(category, currentGameRouter) {
      axios.get('/crazygames.json').then(response => {
        const sameCategoryGames = response.data.filter(game => game.class === category && game.router !== currentGameRouter);
        this.recommendedGames = this.getRandomGames(sameCategoryGames, 30);
      }).catch(error => {
        console.error("Error loading recommended games:", error);
      });
    },
    getRandomGames(games, count) {
      const shuffled = games.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    },
    searchGames() {
      this.$router.push({ path: '/', query: { search: this.searchQuery } });
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    handleTagClick(tag) {
      if (this.categories.includes(tag)) {
        this.$router.push(`/category/${tag}`);
      } else {
        this.$router.push(`/tag/${tag}`);
      }
    }
  }
};
</script>

<style scoped>
.content-container {
  display: flex;
  position: relative;
}

.main-content {
  margin-top: 60px; /* 确保正文部分不被 header 遮挡 */
  flex: 1;
  padding: 20px;
}

.iframe-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.iframe-wrapper iframe {
  width: 100%;
  max-width: 1128px;
  height: 660px;
}

.game-details {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.game-details h1 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.game-details p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.game-details p strong {
  color: #000;
}

.tags {
  margin-top: 10px;
}

.tag {
  display: inline-block;
  background-color: #3498db;
  color: white;
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag:hover {
  background-color: #2980b9;
}

.recommendations {
  margin-top: 40px;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style> 